@import '@aws-amplify/ui-react/styles.css';

/* 
  aws authenticator component - style overrides
  see: https://ui.docs.amplify.aws/react/theming/css-variables
*/
[data-amplify-authenticator] {
  /* font colors */
  --amplify-colors-font-primary: #122634;

  /* border colors */
  --amplify-colors-border-primary: #d1d5db;
  --amplify-colors-border-focus: #d1d5db;
  --amplify-colors-border-disabled: #d1d5db;
  --amplify-colors-border-error: #d1d5db;

  /* authenticator */
  --amplify-components-authenticator-router-border-color: #d1d5db;
  --amplify-components-authenticator-container-width-max: 100%;

  /* field controls */
  --amplify-components-field-font-size: 0.9rem;
  --amplify-components-fieldcontrol-border-radius: 0.5rem;
  --amplify-components-fieldcontrol-border-color: #d1d5db;
  --amplify-components-fieldcontrol-active-border-color: #122634;
  --amplify-components-fieldcontrol-focus-border-color: #122634;
  --amplify-components-fieldcontrol-hover-border-color: #122634;
  --amplify-components-fieldcontrol-focus-box-shadow: #122634;

  /* text fields */
  --amplify-components-textfield-border-color: #d1d5db;
  --amplify-components-textfield-active-border-color: #122634;
  --amplify-components-textfield-focus-border-color: #122634;
  --amplify-components-textfield-hover-border-color: #122634;

  /* buttons */
  --amplify-components-button-primary-color: #122634;
  --amplify-components-button-primary-background-color: #b2ece7;
  --amplify-components-button-primary-active-color: #455966;
  --amplify-components-button-primary-active-background-color: #b2ece7;
  --amplify-components-button-primary-focus-color: #455966;
  --amplify-components-button-primary-focus-background-color: #b2ece7;
  --amplify-components-button-primary-hover-color: #455966;
  --amplify-components-button-primary-hover-background-color: #b2ece7;
  --amplify-components-button-border-radius: 0.5rem;

  /* button links */
  --amplify-components-button-link-color: #122634;
  --amplify-components-button-link-active-color: #122634;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-color: #122634;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-color: #122634;
  --amplify-components-button-link-hover-background-color: transparent;
}

/* hide border/shadow for authenticator component */
[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  border-style: none;
}

/* remove form padding in authenticator component */
[data-amplify-authenticator] [data-amplify-form] {
  padding: 0px;
}

/* hide tabs for authenticator component */
[data-amplify-authenticator] .amplify-tabs {
  display: none;
}

[data-amplify-authenticator] .amplify-heading--3 {
  @apply text-lg;
}

[data-amplify-authenticator] .amplify-heading--4 {
  @apply text-base;
}

[data-amplify-authenticator] input[type='number']::-webkit-inner-spin-button,
[data-amplify-authenticator] input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-amplify-authenticator] button.amplify-button {
  @apply border-secondary text-primary text-sm lowercase font-semibold;
}

[data-amplify-authenticator] button.amplify-button:hover {
  @apply bg-secondary;
}

[data-amplify-authenticator] button.amplify-button {
  --amplify-font-weights-normal: 600;
}

[data-amplify-authenticator] button.amplify-button--link {
  @apply btn-ghost mt-1;
}