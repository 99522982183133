@import 'react-datepicker/dist/react-datepicker.css';

/* date picker customizations */
.react-datepicker__input-container, .react-datepicker-wrapper {
  /* height: 18.5px;
  line-height: 18.5px; */
  cursor: pointer;
}

.react-datepicker__input-container > input {
  @apply border-0 focus:border-0 focus:ring-0 outline-none;
  /* height: 18.5px;
  line-height: 18.5px; */
  cursor: pointer;
}

.react-datepicker_header {
  @apply bg-base-100;
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker__day:hover {
  @apply bg-primary text-white;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  @apply bg-secondary text-primary font-semibold;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--in-range) {
  @apply bg-secondary text-primary;
}
