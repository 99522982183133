@import 'react-phone-input-2/lib/style.css';

.react-tel-input .form-control {
  @apply w-full rounded-lg border border-gray-300 bg-transparent;
}

.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag {
  @apply rounded-l-lg;
}
