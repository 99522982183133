/* IBE Styles */
:root {
  @apply bg-white;
}

body {
  @apply bg-white;
}

/* price details table */
table.price-details {
  @apply text-xs md:text-sm w-full;
}

table.price-details th,
td {
  @apply px-1 py-0.5;
}

table.price-details td.price {
  @apply text-right;
}

select:focus {
  outline: 2px solid transparent !important;
}

*:focus-visible {
  outline: 2px solid #122634 !important;
}

/* override focus outline for dropdown */
.dropdown > *:not(summary):focus-visible {
  outline: 2px solid #122634 !important;
}